import axios from "axios";

const fun = (data) => {
    const renderElement = (element) => {
      const node = document.createElement('p');
      node.setAttribute('data-id', element.id)
      node.style.marginLeft = '30px';
      const name = document.createElement('span');
      name.setAttribute('data-node', element.node)
      name.textContent = element.name;
      const price = document.createElement('small');
      price.textContent = ` / ${element.price} p.`;
      node.appendChild(name);
      if (element.node === 0) node.appendChild(price);;
      return node;
    }

    data.sort((a, b) => a.sorthead - b.sorthead);
    
    const createMarkup = (parent) => {
        if (!parent) return;
        const newChilds = data.filter((service) => service.head === Number(parent.dataset.id));
        newChilds.forEach((child) => {
          const p = renderElement(child);
          parent.appendChild(p);
          createMarkup(p); 
        })
    }

    const container = document.querySelector('.container');
    const roots = data.filter((service) => service.head === null);
    roots.forEach((root) => {
      if (root.node === 1) {
        const p = renderElement(root)
        container.appendChild(p);
        createMarkup(p);
      } else {
        const p = renderElement(root)
        container.appendChild(p);
      }
    });
}

axios.get('http://localhost:3100/services')
  .then((response) => {
    fun(response.data);
  })

const inputElement = document.querySelector('input[name=full_name]');
const MAX_PARTS = 3;
inputElement.value.split(/\s+/, MAX_PARTS);

inputElement.addEventListener('input', (evt) => {
  const parts = inputElement.value.split(/\s+/, MAX_PARTS);
  evt.target.setCustomValidity('');

  if (parts.length < MAX_PARTS) {
    inputElement.setCustomValidity('Неверный формат данных');
  }
});


// const MAX_PARTS = 3;
// const inputElement = document.querySelector('input[name=full_name]');

// const parts = inputElement.value.split(/\s+/, MAX_PARTS);

// inputElement.addEventListener('input', () => {
//   inputElement.setCustomValidity('');

//   const parts = inputElement.value.split(/\s+/, MAX_PARTS);

//   if (parts.length < MAX_PARTS) {
//     inputElement.setCustomValidity('Неверный формат данных');
//   }
// });